/* eslint-disable @typescript-eslint/no-explicit-any */
import { PropsWithChildren } from 'react'

import {
  colors,
  createTheme,
  MuiButton,
  MuiCheckbox,
  MuiDialog,
  MuiFilledInput,
  MuiFormControlLabel,
  MuiInputBase,
  MuiTypography,
  shadows,
  typography,
} from '@dentalux/ui-library-core'

import { badgeClasses, cardActionsClasses, cardContentClasses, dialogTitleClasses, menuClasses } from '@mui/material'
import { Components, ThemeOptions, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'

export const medlogColors = {
  green: {
    500: '#EFF5F1',
  },
  blue: {
    300: '#E3EFF5',
    500: '#c6e8fe',
  },
}

const DialogTheme: Components['MuiDialog'] = {
  ...MuiDialog,
  styleOverrides: {
    ...MuiDialog?.styleOverrides,
    paper: {
      ...(MuiDialog?.styleOverrides?.paper as object),
      background: colors.aqua[50],
      color: colors.grey[800],
      maxWidth: undefined,

      [`& .${dialogTitleClasses.root}`]: {
        ...(MuiDialog?.styleOverrides?.paper as any)[`& .${dialogTitleClasses.root}`],
        background: colors.aqua[50],
        color: colors.grey[800],
        fontSize: '24px',
        padding: '24px',
        paddingBottom: '8px',
      },
    },
  },
}

const CardTheme: Components['MuiCard'] = {
  variants: [
    {
      props: { variant: 'outlined' },
      style: {
        borderRadius: '28px',
        borderColor: medlogColors.green[500],
        borderWidth: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',

        [`.${cardContentClasses.root}`]: {
          flex: 1,
          overflow: 'auto',
          padding: '20px 30px',
        },
        [`.${cardActionsClasses.root}`]: {
          justifyContent: 'center',
          paddingBottom: '14px',
        },
      },
    },
  ],
}

const CardHeaderTheme: Components['MuiCardHeader'] = {
  variants: [
    {
      props: { color: 'medlog' },
      style: {
        background: medlogColors.green[500],
        padding: '15px',
        paddingLeft: '25px',

        [`& .${menuClasses.root}`]: {
          background: 'red',
        },
      },
    },
  ],
}

const SelectTheme: Components['MuiSelect'] = {
  styleOverrides: {
    root: {
      background: medlogColors.green[500],
      borderRadius: '20px',
    },
  },
}

const MenuItemTheme: Components['MuiMenuItem'] = {
  styleOverrides: {
    root: {
      backgroundColor: medlogColors.green[500],
      '&:hover': {
        opacity: 0.9,
      },

      '&.Mui-selected': {
        backgroundColor: medlogColors.blue[500],
        '&:hover': {
          opacity: 0.9,
        },
      },
    },
  },
}

const ListSubheaderTheme: Components['MuiListSubheader'] = {
  styleOverrides: {
    root: {
      fontSize: '16px',
      fontWeight: 700,
      color: colors.grey[800],
      backgroundColor: medlogColors.green[500],
    },
  },
}

const BadgeTheme: Components['MuiBadge'] = {
  variants: [
    {
      props: { color: 'info' },
      style: {
        [`& .${badgeClasses.badge}`]: {
          backgroundColor: colors.grey[50],
          color: colors.grey[800],
        },
      },
    },
  ],
}

const TabPanelTheme: Components['MuiTabs'] = {
  styleOverrides: {
    indicator: {
      display: 'none',
    },
    flexContainer: {
      gap: '8px',
    },
    root: {
      backgroundColor: medlogColors.green[500],
      width: 'fit-content',
      borderRadius: '6px',
      padding: '1px',
    },
  },
}

const TabsTheme: Components['MuiTab'] = {
  styleOverrides: {
    root: {
      background: medlogColors.green[500],
      color: colors.grey[900],
      textTransform: 'none',
      fontSize: '14px',
      lineHeight: '28px',
      textAlign: 'center',
      padding: '0px 32px',
      borderRadius: '6px',
      letterSpacing: '0.5px',

      '&.Mui-selected': {
        background: colors.aqua[500],
        boxShadow: '0px 1px 1px 0px rgba(33, 33, 33, 0.06)',
        letterSpacing: '0.15px',
        fontWeight: 700,

        color: colors.grey[0],
        fontSize: '16px',
      },
    },
  },
}

const theme = createTheme({
  typography,
  components: {
    MuiButton,
    MuiTypography,
    MuiDialog: DialogTheme,
    MuiFilledInput,
    MuiInputBase,
    MuiCheckbox,
    MuiFormControlLabel,
    MuiCard: CardTheme,
    MuiCardHeader: CardHeaderTheme,
    MuiSelect: SelectTheme,
    MuiMenuItem: MenuItemTheme,
    MuiListSubheader: ListSubheaderTheme,
    MuiBadge: BadgeTheme,
    MuiTab: TabsTheme,
    MuiTabs: TabPanelTheme,
  },
  palette: {},
  shadows,
} as Partial<ThemeOptions>)

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}
