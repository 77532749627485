// replace the user pool region, id, and app client id details
import { Amplify, Auth } from 'aws-amplify'

export const configureCognitoAuth = () => {
  Amplify.register(Auth)
  Amplify.configure({
    ssr: true,
    Auth: {
      region: process.env.NEXT_PUBLIC_AWS_COGNITO_REGION,
      userPoolId: process.env.NEXT_PUBLIC_AWS_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.NEXT_PUBLIC_AWS_COGNITO_APP_CLIENT_ID,
    },
  })
}
