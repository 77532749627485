import { forwardRef, ReactNode, useCallback, useMemo, useState } from 'react'

import { useTranslation } from 'next-i18next'

import CheckIcon from '@mui/icons-material/Check'
import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FileCopyIcon from '@mui/icons-material/FilterNone'
import PriorityHighSharpIcon from '@mui/icons-material/PriorityHighSharp'
import WarningIcon from '@mui/icons-material/Warning'
import { Box, Collapse, Typography } from '@mui/material'

import { Field } from '~/types/ServerError'

import copy from 'copy-to-clipboard'
import { SnackbarContent, SnackbarMessage, VariantType } from 'notistack'

import * as S from './Snackbar.styles'

export interface SnackbarProps {
  message: string | ReactNode
  details?: SnackbarMessage
  variant?: VariantType
  status?: number
  onClose: () => void
  fields?: Array<Field>
  sentryId?: string
}

export const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>(
  ({ message, variant = 'info', status, details, onClose, fields, sentryId }, ref) => {
    const [expanded, setExpanded] = useState(false)
    const { t } = useTranslation()

    const Icon = useMemo(() => {
      switch (variant) {
        case 'error':
          return CloseSharpIcon
        case 'success':
          return CheckIcon
        case 'warning':
          return WarningIcon
        default:
          return PriorityHighSharpIcon
      }
    }, [variant])

    const handleExpandClick = useCallback(() => {
      setExpanded((previousExpanded) => !previousExpanded)
    }, [])

    const handleCopyButtonClick = useCallback(() => {
      copy(sentryId ?? '')
    }, [sentryId])

    const showExpandArrow = !!details || !!sentryId

    return (
      <SnackbarContent ref={ref}>
        <S.SnackbarCard variantType={variant}>
          <S.CloseButton onClick={onClose} size="md">
            <CloseSharpIcon />
          </S.CloseButton>
          <S.SnackbarActions variantType={variant}>
            <S.CardWrapper>
              <S.IconWrapper variantType={variant}>
                <Icon />
              </S.IconWrapper>
              <Typography variant="Lato Emphasized 1">{message}</Typography>
            </S.CardWrapper>

            {showExpandArrow && (
              <S.ArrowWrapper>
                <S.ArrowIcon aria-label="Show more" expanded={expanded} onClick={handleExpandClick}>
                  <ExpandMoreIcon />
                </S.ArrowIcon>
              </S.ArrowWrapper>
            )}
          </S.SnackbarActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <S.SnackbarPaper>
              {status && (
                <Box mb={2}>
                  <Typography variant="Lato Emphasized 1" fontWeight={700}>
                    {status} {t('errors.error')}
                  </Typography>
                </Box>
              )}

              {!fields?.length && (
                <Typography variant="Lato Emphasized 1" fontWeight={400}>
                  {details}
                </Typography>
              )}

              {!!fields?.length && (
                <ul>
                  {fields.map(({ field, message }) => (
                    <li key={field}>{`${field} ${message}`}</li>
                  ))}
                </ul>
              )}

              {sentryId && (
                <>
                  <Box mt={1}>
                    <Typography variant="Lato Emphasized 1">{t('errors.copy_link')}:</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
                    <Typography variant="Lato Emphasized 1" fontSize={11}>
                      {sentryId}
                    </Typography>
                    <S.CopyButton startIcon={<FileCopyIcon />} onClick={handleCopyButtonClick} size="md">
                      {t('errors.copy')}
                    </S.CopyButton>
                  </Box>
                </>
              )}
            </S.SnackbarPaper>
          </Collapse>
        </S.SnackbarCard>
      </SnackbarContent>
    )
  }
)
