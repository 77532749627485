import { useEffect, useState } from 'react'

export const useMounted = (deps: Array<unknown> = []) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)

    return () => {
      setMounted(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps])

  return mounted
}
