import { useCallback, useEffect, useState } from 'react'

import { Auth } from 'aws-amplify'

export const useCookieBanner = () => {
  const [userAuthenticated, setUserAuthenticated] = useState(true)

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .catch(() => {})
      .then((u) => setUserAuthenticated(!!u))
  }, [])

  const hideCookieButton = useCallback(() => {
    document.getElementById('usercentrics-root')?.remove()
  }, [])

  return { shouldInstallScript: !userAuthenticated, hideCookieButton }
}
