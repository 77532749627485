import { colors } from '@dentalux/ui-library-core'

import { Button, Card, CardProps, CardActions, IconButton, Paper, styled, css } from '@mui/material'

import { VariantType } from 'notistack'

type SnackbarActionsProps = {
  variantType: VariantType
}

export const SnackbarCard = styled(Card, { shouldForwardProp: (prop) => prop !== 'variantType' })<
  CardProps & SnackbarActionsProps
>`
  color: ${colors.grey[0]};
  width: 400px;
  border-radius: 8px;
  font-size: 11px;
`

export const CardWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SnackbarActions = styled(CardActions, {
  shouldForwardProp: (prop) => prop !== 'variantType',
})<SnackbarActionsProps>`
  min-height: 56px;
  align-items: center;
  padding: 8px 16px;
  justify-content: space-between;
  box-sizing: border-box;
  color: ${colors.grey[800]};
  border: 1px solid ${colors.grey[800]};
  border-radius: 8px;

  ${({ variantType }) => {
    if (variantType === 'info' || variantType === 'default')
      return css`
        background-color: ${colors.aqua[50]};
        border-color: ${colors.aqua[600]};
      `

    if (variantType === 'error')
      return css`
        background-color: ${colors.error[50]};
        border-color: ${colors.error[600]};
      `

    if (variantType === 'success')
      return css`
        background-color: ${colors.success[50]};
        border-color: ${colors.success[200]};
      `

    if (variantType === 'warning')
      return css`
        background-color: ${colors.warning[50]};
        border-color: ${colors.warning[600]};
      `
  }}
`

export const IconWrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'variantType' })<SnackbarActionsProps>`
  margin-right: 16px;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.grey[800]};

  svg {
    fill: ${colors.grey[0]};
    width: 20px;
    height: 20px;
  }

  ${({ variantType }) => {
    if (variantType === 'success')
      return css`
        background: ${colors.success[600]};
      `
    if (variantType === 'warning')
      return css`
        svg {
          width: 32px;
          height: 32px;
          fill: ${colors.warning[600]};
        }
      `
    if (variantType === 'error')
      return css`
        border-radius: 0;
        background: ${colors.error[600]};
        svg {
          width: 24px;
          height: 24px;
          fill: ${colors.grey[0]};
        }
      `
    if (variantType === 'default' || variantType === 'info')
      return css`
        background: ${colors.aqua[600]};
        border-radius: 0;
        transform: rotate(45deg);

        svg {
          transform: rotate(135deg);
        }
      `
  }}
`

export const ArrowWrapper = styled('div')`
  margin-left: auto;
`

interface ArrowIconProps {
  expanded: boolean
}

export const ArrowIcon = styled(IconButton)<ArrowIconProps>`
  padding: 8px;
  transform: rotate(0deg);

  &&& svg {
    fill: ${colors.grey[800]};
  }

  ${({ expanded }) =>
    expanded &&
    css`
      transform: rotate(180deg);
    `}
`

export const SnackbarPaper = styled(Paper)`
  padding: 16px;
  font-size: 13px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: transparent;
  font-size: 13px;
`

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: -6px;
  top: -6px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid ${colors.grey[900]};
  background: #fff;
  padding: 0;

  &:hover {
    background: #fff;
  }

  & svg {
    width: 12px;
    height: 12px;
  }
`

export const CopyButton = styled(Button)`
  && {
    font-size: 11px;
  }
`
