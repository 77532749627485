import React, { PropsWithChildren } from 'react'

import { KeyPrefix, Namespace, useTranslation, UseTranslationResponse } from 'react-i18next'

import { Field } from '~/types/ServerError'

import {
  useSnackbar,
  VariantType,
  ProviderContext,
  SnackbarProvider as NotistackProvider,
  SnackbarProviderProps,
} from 'notistack'

import { Snackbar } from '../components/Snackbar'

let snackbarRef: ProviderContext
let translationsRef: UseTranslationResponse<Namespace<string>, KeyPrefix<Namespace<string>>>

// Required to work with snackbar messages outside components
const SnackbarProviderConfigurator = () => {
  snackbarRef = useSnackbar()
  translationsRef = useTranslation()
  return null
}

const MAX_SNACKBARS_VISIBLE = 5

export const SnackbarProvider = ({ children, ...props }: PropsWithChildren<SnackbarProviderProps>) => (
  <NotistackProvider
    {...props}
    maxSnack={MAX_SNACKBARS_VISIBLE}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
  >
    <SnackbarProviderConfigurator />
    {children}
  </NotistackProvider>
)

interface AddSnackbarMessageProps {
  message: string
  variant?: VariantType
  details?: string | undefined
  status?: number
  fields?: Array<Field>
  sentryId?: string
  autoHideDuration?: number
}

export const addSnackbarMessage = ({
  message = '',
  variant = 'default',
  details,
  status,
  fields,
  sentryId,
  autoHideDuration,
}: AddSnackbarMessageProps) => {
  const translation = message && translationsRef?.t(message)

  const handleSnackbarClose = () => snackbarRef?.closeSnackbar()

  snackbarRef?.enqueueSnackbar(message, {
    variant,
    autoHideDuration,
    preventDuplicate: true,
    content: (_, message) => (
      <Snackbar
        message={translation || message || details}
        variant={variant}
        details={translation && details}
        status={status}
        onClose={handleSnackbarClose}
        fields={fields}
        sentryId={sentryId}
      />
    ),
  })
}
