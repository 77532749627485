import { useEffect } from 'react'

import { QueryClientProvider } from 'react-query'

import { appWithTranslation } from 'next-i18next'
import Script from 'next/script'

import { CssBaseline } from '@mui/material'

import { queryClient } from '~/config/query'
import { AppPropsWithLayout } from '~/helpers/types'
import { useCookieBanner } from '~/hooks/useCookieBanner'
import { useMounted } from '~/hooks/useMounted'
import { SnackbarProvider } from '~/providers/SnackbarProvider'
import { ThemeProvider } from '~/providers/ThemeProvider'

import NiceModal from '@ebay/nice-modal-react'
import { PostHogProvider } from 'posthog-js/react'

import '../core/styles/global.css'
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css'

import nextI18NextConfig from '../../next-i18next.config'
import { configureCognitoAuth } from '../core/external/aws-cognito-configuration'
import { configurePosthog } from '../core/external/posthog-configuration'

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const isMounted = useMounted([pageProps?._nextI18Next?.initialLocale])
  const getLayout = Component.getLayout ?? ((page) => page)
  const { shouldInstallScript } = useCookieBanner()

  useEffect(() => {
    configureCognitoAuth()
  }, [])

  return isMounted ? (
    <ThemeProvider>
      {shouldInstallScript && (
        <Script
          src={process.env.NEXT_PUBLIC_COOKIE_BANNER_URL}
          data-settings-id={process.env.NEXT_PUBLIC_COOKIE_BANNER_ID}
          async
          id="usercentrics-cmp"
        />
      )}
      <CssBaseline />
      <SnackbarProvider>
        <PostHogProvider client={configurePosthog()}>
          <QueryClientProvider client={queryClient}>
            <NiceModal.Provider>{getLayout(<Component {...pageProps} />)}</NiceModal.Provider>
          </QueryClientProvider>
        </PostHogProvider>
      </SnackbarProvider>
    </ThemeProvider>
  ) : null
}

export default appWithTranslation(MyApp, nextI18NextConfig)
