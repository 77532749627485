import posthogClient from 'posthog-js'
import * as process from 'process'

export const configurePosthog = () => {
  if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_POSTHOG_KEY && process.env.NODE_ENV !== 'development') {
    posthogClient.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    })
    return posthogClient
  }

  return undefined
}
