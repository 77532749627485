/* eslint-disable @typescript-eslint/no-var-requires */
const { initReactI18next } = require('react-i18next')

const { Backend } = require('@dentalux/i18next-plugins')

const applicationName = 'medlog'
const shouldUsePreview = ['development', 'preview'].includes(process.env.REACT_APP_ENV ?? '')

module.exports = {
  ns: ['translation'],

  backend: {
    applicationName,
    fallbackResource: {
      en: require('./src/i18n/en.json'),
      de: require('./src/i18n/de.json'),
    },
    contentfulConfig: {
      usePreview: shouldUsePreview,
    },
  },

  defaultNS: 'translation',
  i18n: {
    defaultLocale: 'de',
    locales: ['en', 'de'],
    localeDetection: false,
  },
  serializeConfig: false,
  localeDetection: false,
  reloadOnPrerender: true,
  react: {
    useSuspense: false,
  },
  use: [initReactI18next, Backend],
}
